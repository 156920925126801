import { RouteRecordRaw } from "vue-router";

const productReviewRoutes: Array<RouteRecordRaw> = [
  {
    path: "/product-review",
    redirect: "/product-review/list",
    name: "Product Review",
  },
  {
    path: "/product-review/list",
    name: "Product Review List",
    component: () =>
      import(
        /* webpackChunkName: "productReview" */ "../views/ProductReviewListView"
      ),
  },
  {
    path: "/product-review/create",
    name: "Create Product Review",
    component: () =>
      import(
        /* webpackChunkName: "productReview" */ "../views/ProductReviewDetailView"
      ),
  },
  {
    path: "/product-review/detail/:productReviewId",
    name: "Product Review Detail",
    component: () =>
      import(
        /* webpackChunkName: "productReview" */ "../views/ProductReviewDetailView"
      ),
  },
];

export default productReviewRoutes;
