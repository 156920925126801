export const API_URL =
  process.env.VUE_APP_API_URL || "https://vendorap.staging.autonomousdev.xyz";

export const WEB_URL =
  process.env.VUE_APP_WEB_URL || "http://stagingv2.autonomousdev.xyz";

export const GOOGLE_CLIENT_ID =
  process.env.VUE_APP_GOOGLE_CLIENT_ID ||
  "900615547717-iek6qo3ffrqtg74vjmkg1emj6oi37217.apps.googleusercontent.com";

export const OLD_VENDOR_WEB_URL =
  process.env.VUE_APP_OLD_VENDOR_WEB_URL ||
  "https://vendor.staging.autonomousdev.xyz";
