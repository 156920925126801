import { IProfile } from "@/@types";

export type AuthState = {
  accessToken?: string;
  refreshToken?: string;
  profile?: IProfile;
};

export const authState: AuthState = {
  profile: undefined,
};
