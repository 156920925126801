
import { defineComponent, ref } from "vue";
import { VueAvatar } from "vue-avatar-editor-improved";
import { RotateLeftOutlined, RotateRightOutlined } from "@ant-design/icons-vue";

interface CropState {
  visible: boolean;
  data: {
    file?: File;
    onFinish?: (image?: File) => void;
    onCancel?: () => void;
  }[];
}

export default defineComponent({
  components: { VueAvatar, RotateLeftOutlined, RotateRightOutlined },
  computed: {
    cropImageState(): CropState {
      return this.$store.getters["modal/cropImage"];
    },
    dimensions(): { width: number; height: number; border: number }[] {
      const inputData = this.$store.getters["modal/cropImage"]?.data || [];
      return (
        inputData?.map?.((item) => {
          const { width = 1200, ratio = 4 / 3 } = item || {};

          return {
            width,
            height: Math.floor(width / ratio),
            border:
              width < 400
                ? Math.floor((400 - width) / 2)
                : Math.floor(width / 12),
          };
        }) || []
      );
    },
  },
  setup() {
    const activeIdx = ref<number>(0);
    const scales = ref<number[]>([]);
    const rotates = ref<number[]>([]);

    return {
      activeIdx,
      itemRefs: [] as InstanceType<typeof VueAvatar>[],
      scales,
      rotates,
    };
  },
  beforeUpdate() {
    this.itemRefs = [];
  },
  watch: {
    "cropImageState.data.length"() {
      if (this.cropImageState?.data?.length > 0) {
        this.scales = this.cropImageState?.data.map(() => 1);
        this.rotates = this.cropImageState?.data.map(() => 0);
        this.activeIdx = 0;
      }
    },
  },
  methods: {
    setItemRef(el: InstanceType<typeof VueAvatar>) {
      if (el && !this.itemRefs?.includes?.(el)) {
        this.itemRefs.push(el);

        // override click func
        if (el?.clicked) {
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          el.clicked = () => {
            if (el.dragged === true) {
              el.dragged = false;
            }
          };
        }
      }
    },
    getThumbs() {
      return (this.cropImageState.data || []).map((item) =>
        item.file ? URL.createObjectURL(item.file) : ""
      );
    },
    changeItem(idx: number) {
      this.activeIdx = idx;
    },
    rotateLeft() {
      const currentRotate = this.rotates[this.activeIdx] || 0;
      this.rotates[this.activeIdx] =
        currentRotate === -270 ? 0 : currentRotate - 90;
    },
    rotateRight() {
      const currentRotate = this.rotates[this.activeIdx] || 0;
      this.rotates[this.activeIdx] =
        currentRotate === 270 ? 0 : currentRotate + 90;
    },
    getImageSrc(file: File) {
      if (file?.size) {
        return URL.createObjectURL(file);
      }
      return "";
    },
    cancel() {
      if (this.cropImageState.data?.length > 0) {
        this.cropImageState.data.forEach((item) => item?.onCancel?.());
      }
      this.$store.dispatch("modal/HIDE_CROP_IMAGE_MODAL");
    },
    async click() {
      const imgs = this.itemRefs.map((ref: InstanceType<typeof VueAvatar>) =>
        ref.getImageScaled()
      );

      await Promise.all(
        imgs.map(
          (img: any, idx: number) =>
            new Promise((resolve) => {
              img.toBlob((blob: File) => {
                this.cropImageState.data?.[idx]?.onFinish?.(blob);
                resolve(true);
              });
            })
        )
      );
      this.$store.dispatch("modal/HIDE_CROP_IMAGE_MODAL");
    },
  },
});
