
import { computed, defineComponent, ref } from "vue";
import { NotificationOutlined } from "@ant-design/icons-vue";
import { useRequest } from "vue-request";
import { VendorApi } from "@/apis";
import NotificationContent from "./NotificationContent.vue";

const LATEST_VIEW_NOTIFICATION_ID = "LATEST_VIEW_NOTIFICATION_ID";

export default defineComponent({
  components: {
    NotificationOutlined,
    NotificationContent,
  },
  setup() {
    const { data } = useRequest(() =>
      VendorApi.getNotificationList({ limit: 50 })
    );
    const notificationList = computed(() => data.value || []);
    const latestId = ref<string>(
      localStorage.getItem(LATEST_VIEW_NOTIFICATION_ID) || ""
    );

    const onViewNotification = () => {
      if (notificationList.value?.[0]?.id) {
        latestId.value = notificationList.value?.[0].id;
        localStorage.setItem(
          LATEST_VIEW_NOTIFICATION_ID,
          notificationList.value?.[0].id
        );
      }
    };

    const seen = computed(
      () => latestId.value && latestId.value === notificationList.value?.[0]?.id
    );

    return {
      notificationList,
      onViewNotification,
      seen,
    };
  },
});
