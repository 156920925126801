export type ModalState = {
  previewImage: {
    visible: boolean;
    thumbUrl?: string;
    src?: string;
  };
  cropImage: {
    visible: boolean;
    data: {
      file?: File;
      width?: number;
      ratio?: number;
      onFinish?: (image?: File) => void;
      onCancel?: () => void;
    }[];
  };
};

export const modalState: ModalState = {
  previewImage: {
    visible: false,
  },
  cropImage: {
    visible: false,
    data: [],
  },
};
