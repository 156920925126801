import { RouteRecordRaw } from "vue-router";

const importRoutes: Array<RouteRecordRaw> = [
  {
    path: "/import",
    name: "Import",
    redirect: "/import-tracking",
  },
  {
    path: "/import-tracking",
    name: "Import Tracking",
    component: () =>
      import(/* webpackChunkName: "import" */ "../views/ImportTrackingView"),
  },
  {
    path: "/import-product-review",
    name: "Import Product Review",
    component: () =>
      import(
        /* webpackChunkName: "import" */ "../views/ImportProductReviewView"
      ),
  },
  {
    path: "/import-product-faq",
    name: "Import Product Faq",
    component: () =>
      import(/* webpackChunkName: "import" */ "../views/ImportProductFaqView"),
  },
  {
    path: "/import-inventory",
    name: "Import Inventory",
    component: () =>
      import(/* webpackChunkName: "import" */ "../views/ImportInventoryView"),
  },
  {
    path: "/import-product-gtin",
    name: "Import Product GTIN",
    component: () =>
      import(/* webpackChunkName: "import" */ "../views/ImportProductGtinView"),
  },
];

export default importRoutes;
