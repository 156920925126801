
import { Rule } from "ant-design-vue/lib/form";
import { defineComponent, reactive, ref } from "vue";
import { AuthApi } from "@/apis";

interface IFormState {
  email: string;
}

export default defineComponent({
  setup() {
    const formState = reactive<IFormState>({
      email: "",
    });
    const loading = ref<boolean>(false);
    const visibleForm = ref<boolean>(true);

    const rules: Partial<Record<keyof IFormState, Rule[]>> = {
      email: [
        { type: "email", message: "Please input valid email!" },
        { required: true, message: "Please input your email!" },
      ],
    };

    const onSubmit = async (values: IFormState) => {
      loading.value = true;
      const isSuccess = await AuthApi.forgotPassword(values.email);
      if (isSuccess) {
        visibleForm.value = false;
      }
      loading.value = false;
    };

    return {
      loading,
      formState,
      rules,
      onSubmit,
      visibleForm,
    };
  },
});
