import ApiService, { ApiResponse } from "../ApiService";

const BASE_URL = "/shipment";

const ShipmentApi = {
  async updateStatus(params: { id: string; status: number }): Promise<boolean> {
    const { id, status } = params;
    const res: ApiResponse<string> = await ApiService.put(
      `${BASE_URL}/${id}/update-status`,
      { status }
    );
    return res.ok;
  },
  async updateReturnTracking(params: {
    id: string;
    order_detail_id: string;
    sku: string;
    tracking_code: string;
    tracking_link: string;
    vendor_code: string;
  }): Promise<boolean> {
    const { id, ...others } = params;
    const res: ApiResponse<string> = await ApiService.put(
      `${BASE_URL}/${id}/update-return-tracking`,
      others
    );
    return res.ok;
  },
  async updateTracking(params: {
    id: string;
    order_detail_id: string;
    sku: string;
    tracking_code: string;
    tracking_link: string;
    vendor_code: string;
  }): Promise<boolean> {
    const { id, ...others } = params;
    const res: ApiResponse<string> = await ApiService.put(
      `${BASE_URL}/${id}/update-tracking`,
      others
    );
    return res.ok;
  },
};

export default ShipmentApi;
