
import dayjs from "dayjs";
import { defineComponent } from "vue";
import { computed, ref } from "vue";
const MAX_TEXT_LENGTH = 160;

export default defineComponent({
  props: {
    content: {
      type: String,
      required: true,
    },
    createdDate: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isShowAll = ref<boolean>(false);
    const isLongText = computed(() => props.content.length > MAX_TEXT_LENGTH);
    const toggle = () => (isShowAll.value = !isShowAll.value);
    const formattedCreatedDate = computed(() => {
      console.log(
        "%cNotificationContent.vue line:41 props.createdDate",
        "color: #007acc;",
        props.createdDate
      );
      const day = dayjs(props.createdDate).format("MMM DD, YYYY");
      const time = dayjs(props.createdDate).format("HH:mm");
      return `${day} at ${time}`;
    });

    return {
      isLongText,
      isShowAll,
      toggle,
      formattedCreatedDate,
    };
  },
});
