import type {
  ICategory,
  IPackageOption,
  IProduct,
  IProductOption,
} from "@/@types";
import { removeEmpty } from "@/utils";
import ApiService, { ApiResponse } from "../ApiService";
import {
  IInventoryProcessListResponse,
  IProductListResponse,
} from "./ProductApi.types";
import { CONTENT_TYPE } from "@/predefined/contants";

const BASE_URL = "/product";

const ProductApi = {
  async getList(params?: {
    limit?: number;
    page?: number;
    keyword?: string;
    product_code?: string;
    status?: number;
  }) {
    const response: ApiResponse<IProductListResponse> = await ApiService.get(
      `${BASE_URL}/list`,
      { params: removeEmpty(params) }
    );
    if (response.ok) {
      return { dataSource: response.data.data, total: response.data.total };
    }
    return { dataSource: [], total: 0 };
  },
  async createOrUpdate(payload: Partial<IProduct>) {
    if (payload.id) {
      const response: ApiResponse<{ id: string }> = await ApiService.patch(
        `${BASE_URL}/${payload.id}`,
        payload
      );
      return response;
    } else {
      const response: ApiResponse<{ id: string }> = await ApiService.post(
        `${BASE_URL}/create`,
        payload
      );
      return response;
    }
  },
  async createProductOptions(
    productId: string,
    payload: {
      package_options: IPackageOption[];
      product_options: IProductOption[];
    }
  ) {
    const response: ApiResponse<{ id: string }> = await ApiService.post(
      `${BASE_URL}/${productId}/options`,
      payload
    );
    return response.ok;
  },
  async updateProductOption(
    productId: string,
    payload: Partial<IProductOption>
  ) {
    const response: ApiResponse<{ id: string }> = await ApiService.patch(
      `${BASE_URL}/${productId}/options/${payload.sequence_code}`,
      payload
    );
    return response.ok;
  },
  async getDetail(productId?: string): Promise<IProduct | null> {
    if (!productId) {
      return null;
    }
    const res: ApiResponse<IProduct> = await ApiService.get(
      `${BASE_URL}/${productId}`
    );
    if (res.ok) {
      return res.data;
    }
    return null;
  },
  async getCategoryList() {
    const response: ApiResponse<ICategory[]> = await ApiService.get(
      `${BASE_URL}/categories`
    );
    if (response.ok) {
      return response.data;
    }
    return [];
  },
  async import(file: Blob): Promise<boolean> {
    const formData = new FormData();

    formData.append("file", file);
    const response: ApiResponse<any> = await ApiService.post(
      `${BASE_URL}/import`,
      formData,
      {
        headers: { "Content-Type": CONTENT_TYPE.MULTIPART },
        params: {
          public: true,
        },
      }
    );
    return !!response.ok;
  },
  async importGtin(file: Blob): Promise<boolean> {
    const formData = new FormData();

    formData.append("file", file);
    const response: ApiResponse<any> = await ApiService.post(
      `${BASE_URL}/import-gtin`,
      formData,
      {
        headers: { "Content-Type": CONTENT_TYPE.MULTIPART },
        params: {
          public: true,
        },
      }
    );
    return !!response;
  },

  // Inventory
  async importInventory(file: Blob): Promise<boolean> {
    const formData = new FormData();

    formData.append("file", file);
    const response: string = await ApiService.post(
      `${BASE_URL}/import-inventory`,
      formData,
      {
        headers: { "Content-Type": CONTENT_TYPE.MULTIPART },
        responseType: "text",
        params: {
          public: true,
        },
      }
    );
    return !!response;
  },
  async getInventoryProcessList(params?: {
    limit?: number;
    page?: number;
    keyword?: string;
    product_code?: string;
    status?: number;
  }) {
    const response: ApiResponse<IInventoryProcessListResponse> =
      await ApiService.get(`${BASE_URL}/import-inventory-process`, {
        params: removeEmpty(params),
      });
    if (response.ok) {
      return {
        dataSource: response.data.import_inventory_processes || [],
        total: response.data.total_records || 0,
      };
    }
    return { dataSource: [], total: 0 };
  },
  async export(params?: {
    limit?: number;
    page?: number;
    keyword?: string;
    product_code?: string;
    status?: number;
  }) {
    const res: ApiResponse<any> = await ApiService.post(
      `${BASE_URL}/export`,
      params
    );

    return !!res;
  },
};

export default ProductApi;
