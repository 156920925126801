import { MutationTree } from "vuex";

import { ModalState } from "./state";
import { ModalMutationTypes } from "./mutation-types";

export type ModalMutations<S = ModalState> = {
  [ModalMutationTypes.SET_PREVIEW_IMAGE_MODAL](
    state: S,
    payload: ModalState["previewImage"]
  ): void;
  [ModalMutationTypes.SET_CROP_IMAGE_MODAL](
    state: S,
    payload: ModalState["cropImage"]
  ): void;
};

export const modalMutations: MutationTree<ModalState> & ModalMutations = {
  [ModalMutationTypes.SET_PREVIEW_IMAGE_MODAL](state, previewImage) {
    state.previewImage = previewImage;
  },
  [ModalMutationTypes.SET_CROP_IMAGE_MODAL](state, cropImage) {
    state.cropImage = cropImage;
  },
};
