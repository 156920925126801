import { RouteRecordRaw } from "vue-router";

const inventoryRoutes: Array<RouteRecordRaw> = [
  {
    path: "/inventory",
    redirect: "/inventory/list",
    name: "Inventory",
  },
  {
    path: "/inventory/list",
    name: "Inventory List",
    component: () =>
      import(/* webpackChunkName: "inventory" */ "../views/InventoryListView"),
  },
];

export default inventoryRoutes;
