
import { defineComponent } from "vue";
import "lazysizes";

export default defineComponent({
  computed: {
    previewImageState() {
      return this.$store.getters["modal/previewImage"];
    },
  },
  methods: {
    cancel() {
      this.$store.dispatch("modal/HIDE_PREVIEW_IMAGE_MODAL");
    },
  },
});
