export const ENUM_ORDER_DETAIL_STATUS = {
  PENDING: {
    id: 0,
    name: "Pending",
  },
  SHIPPED: {
    id: 1,
    name: "Shipped",
  },
  DELIVERED: {
    id: 2,
    name: "Delivered",
  },
  CANCELLED: {
    id: 3,
    name: "Cancelled",
  },
  RETURNAFTERUSED: {
    id: 4,
    name: "Return After Used",
  },
  REFUNDAFTER30DAYS: {
    id: 5,
    name: "Return After 30 Days",
  },
  INRETURNREVIEW: {
    id: 6,
    name: "In Return Review",
  },
  LABEL_PRINTING_READY: {
    id: 7,
    name: "Label Printing Ready",
  },
  SWITCHPENDING: {
    id: 8,
    name: "Switch Pending",
  },
  RETURNED: {
    id: 9,
    name: "Returned",
  },
  HOLDFORSHIPMENT: {
    id: 14,
    name: "Hold For Shipment",
  },
  PARTIAL_SHIP: {
    id: 15,
    name: "Partial Shipped",
  },
  PARTIAL_DELIVER: {
    id: 16,
    name: "Partial Delivered",
  },
  IN_RETURN_REVIEW: {
    id: 17,
    name: "In Return Process",
  },
  IN_EXCHANGE_REVIEW: {
    id: 18,
    name: "In Exchange Review",
  },
  IN_EXCHANGE_PROCESS: {
    id: 19,
    name: "In Exchange Process",
  },
  RETURN_FOR_EXCHANGE: {
    id: 20,
    name: "Return For Exchange",
  },
  CANCEL_WITHOUT_PAYMENT: {
    id: 21,
    name: "Cancelled without payment",
  },
};
