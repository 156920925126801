
import { computed, defineComponent, onMounted, ref } from "vue";
import { adminRoutes } from "@/router";
import {
  CodeSandboxOutlined,
  ShoppingCartOutlined,
  HomeOutlined,
  DownOutlined,
  LogoutOutlined,
  ImportOutlined,
  FileProtectOutlined,
  MessageOutlined,
  NotificationOutlined,
  MenuOutlined,
  FormOutlined,
  ShopOutlined,
  EyeOutlined,
  InboxOutlined,
  GlobalOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons-vue";
import { useRoute } from "vue-router";
import NotificationPanel from "@/components/NotificationPanel";
import { useStore } from "@/store";
import { OLD_VENDOR_WEB_URL, WEB_URL } from "@/config";
import { OrderApi } from "@/apis";
import { ENUM_ORDER_DETAIL_STATUS } from "@/predefined/enums";
import { useRequest } from "vue-request";

type IMenuItem = {
  title: string;
  routeName: string;
  icon: unknown;
};
type IMenu = IMenuItem & { children?: IMenuItem[] };

const menus: IMenu[] = [
  {
    title: "Product",
    routeName: "Product List",
    icon: CodeSandboxOutlined,
  },
  {
    title: "Order",
    routeName: "Order List",
    icon: ShoppingCartOutlined,
    // children: [
    //   {
    //     title: "Order Test",
    //     routeName: "About",
    //     icon: PieChartOutlined,
    //   },
    // ],
  },
  {
    title: "Inventory",
    routeName: "Inventory List",
    icon: InboxOutlined,
  },
  {
    title: "Manage reviews",
    routeName: "Product Review List",
    icon: MessageOutlined,
  },
  {
    title: "Product FAQ",
    routeName: "Product Faq List",
    icon: QuestionCircleOutlined,
  },
  {
    title: "Policy",
    routeName: "Policy",
    icon: FileProtectOutlined,
  },
  {
    title: "Customize",
    icon: FormOutlined,
    routeName: "Vendor",
    children: [
      {
        title: "URL Page",
        routeName: "Vendor Url",
        icon: MenuOutlined,
      },
      {
        title: "Edit your homepage",
        routeName: "Vendor Detail",
        icon: MenuOutlined,
      },
    ],
  },
  {
    title: "Import",
    icon: ImportOutlined,
    routeName: "Import",
    children: [
      {
        title: "Import Tracking",
        routeName: "Import Tracking",
        icon: MenuOutlined,
      },
      {
        title: "Import Product Review",
        routeName: "Import Product Review",
        icon: MenuOutlined,
      },
      {
        title: "Import Product Faq",
        routeName: "Import Product Faq",
        icon: MenuOutlined,
      },
      {
        title: "Import Inventory",
        routeName: "Import Inventory",
        icon: MenuOutlined,
      },
      {
        title: "Import Product GTIN",
        routeName: "Import Product GTIN",
        icon: MenuOutlined,
      },
    ],
  },
];

const breadcrumbMap: Record<string, { title: string; routeName: string }[]> =
  menus.reduce((result, menu) => {
    if (!menu.children || !menu.children?.length) {
      return {
        ...result,
        [menu.routeName]: [
          {
            title: menu.title,
            routeName: menu.routeName,
          },
        ],
      };
    }
    const temp = menu.children.reduce((tempResult, subMenu) => {
      return {
        ...tempResult,
        [subMenu.routeName]: [
          {
            title: menu.title,
            routeName: menu.routeName,
          },
          {
            title: subMenu.title,
            routeName: subMenu.routeName,
          },
        ],
      };
    }, {});
    return {
      ...result,
      ...temp,
    };
  }, {});

export default defineComponent({
  components: {
    HomeOutlined,
    DownOutlined,
    LogoutOutlined,
    NotificationOutlined,
    NotificationPanel,
    ShopOutlined,
    EyeOutlined,
    GlobalOutlined,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const loading = ref<boolean>(true);

    const { data, run } = useRequest(() => {
      return OrderApi.getList({
        statuses: [ENUM_ORDER_DETAIL_STATUS.LABEL_PRINTING_READY.id],
      });
    });

    const totalOrderPending = computed(() => data.value?.total || 0);

    const profile = computed(() => {
      return store.getters["auth/profile"];
    });

    const vendorUrl = computed(() => {
      if (profile.value?.vendor_slug) {
        return `${WEB_URL}/brand/${profile.value?.vendor_slug}`;
      }
      return WEB_URL;
    });

    onMounted(async () => {
      loading.value = true;
      await store.dispatch("auth/GET_PROFILE");
      loading.value = false;
    });

    return {
      adminRoutes,
      menus,
      route,
      vendorUrl,
      profile,
      loading,
      totalOrderPending,
      OLD_VENDOR_WEB_URL,
    };
  },

  computed: {
    breadcrumbs() {
      return breadcrumbMap[this.$route.name as string];
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/LOGOUT");
    },
    async handleChangeVendor(vendorCode: string) {
      if (vendorCode) {
        const isSuccess = await this.$store.dispatch(
          "auth/SWITCH_VENDOR",
          vendorCode
        );

        if (isSuccess) {
          document.location.reload();
        }
      }
    },
  },
});
