import { RouteRecordRaw } from "vue-router";

const productFaqRoutes: Array<RouteRecordRaw> = [
  {
    path: "/product-faq",
    redirect: "/product-faq/list",
    name: "Product Faq",
  },
  {
    path: "/product-faq/list",
    name: "Product Faq List",
    component: () =>
      import(
        /* webpackChunkName: "productFaq" */ "../views/ProductFaqListView"
      ),
  },
  {
    path: "/product-faq/create",
    name: "Create Product Faq",
    component: () =>
      import(
        /* webpackChunkName: "productFaq" */ "../views/ProductFaqDetailView"
      ),
  },
  {
    path: "/product-faq/detail/:productFaqId",
    name: "Product Faq Detail",
    component: () =>
      import(
        /* webpackChunkName: "productFaq" */ "../views/ProductFaqDetailView"
      ),
  },
];

export default productFaqRoutes;
