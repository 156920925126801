import _memoize from "lodash/memoize";
import _get from "lodash/get";

type IEnum = Record<string, unknown> & {
  id: number | string;
};

type IEnumData = Record<string, IEnum>;

const enumToMap = _memoize((enumData: IEnumData) => {
  const map = Object.values(enumData).reduce(
    (result, item) => ({ ...result, [item.id]: item }),
    {}
  );

  return map;
});

export const findEnumById = (
  enumData: IEnumData,
  id: number | string,
  rawPath?: string
) => {
  const map = enumToMap(enumData);
  const path = rawPath ? `${id}.${rawPath}` : id;

  return _get(map, path);
};

export const enumToList = _memoize((enumData) => Object.values(enumData));
