import { RouteRecordRaw } from "vue-router";

const orderRoutes: Array<RouteRecordRaw> = [
  {
    path: "/order",
    redirect: "/order/list",
    name: "Order",
  },
  {
    path: "/order/list",
    name: "Order List",
    component: () =>
      import(/* webpackChunkName: "order" */ "../views/OrderListView"),
  },
  {
    path: "/order/:orderId",
    name: "Order Detail",
    component: () =>
      import(/* webpackChunkName: "order" */ "../views/OrderDetailView"),
  },
];

export default orderRoutes;
