import { IProductReview } from "@/@types";
import { downloadFile, removeEmpty } from "@/utils";
import ApiService, { ApiResponse } from "../ApiService";
import {
  IProductReviewListResponse,
  IProductReviewProcessListResponse,
} from "./ProductReviewApi.types";
import { CONTENT_TYPE } from "@/predefined/contants";

const BASE_URL = "/product-review";

const ProductReviewApi = {
  async getList(params?: {
    limit?: number;
    page?: number;
    keyword?: string;
    product_code?: string;
    status?: number;
  }) {
    const response: ApiResponse<IProductReviewListResponse> =
      await ApiService.get(`${BASE_URL}`, { params: removeEmpty(params) });
    if (response.ok) {
      return { dataSource: response.data.datas, total: response.data.total };
    }
    return { dataSource: [], total: 0 };
  },
  async create(payload: Partial<IProductReview>) {
    const response: ApiResponse<{ id: string }> = await ApiService.post(
      `${BASE_URL}`,
      payload
    );
    return response;
  },
  async getDetail(productReviewId?: string): Promise<IProductReview | null> {
    if (!productReviewId) {
      return null;
    }
    const res: ApiResponse<IProductReview> = await ApiService.get(
      `${BASE_URL}/${productReviewId}`
    );
    if (res.ok) {
      return res.data;
    }
    return null;
  },
  async export(params?: {
    limit?: number;
    last_id?: string;
    date_from?: string;
    date_to?: string;
    statuses?: number | number[];
  }) {
    const res: ApiResponse<{ fileName: string; file: Blob }> =
      await ApiService.get(`${BASE_URL}/export`, {
        params,
        responseType: "blob",
      });

    if (res.ok) {
      downloadFile(res.data.file, res.data.fileName);
    }
  },
  async import(file: Blob): Promise<boolean> {
    const formData = new FormData();

    formData.append("file", file);
    const response: string = await ApiService.post(
      `${BASE_URL}/import`,
      formData,
      {
        headers: { "Content-Type": CONTENT_TYPE.MULTIPART },
        responseType: "text",
        params: {
          public: true,
        },
      }
    );
    return !!response;
  },
  async getProcessList(params?: {
    limit?: number;
    page?: number;
    keyword?: string;
    product_code?: string;
    status?: number;
  }) {
    const response: ApiResponse<IProductReviewProcessListResponse> =
      await ApiService.get(`${BASE_URL}/import_tracking_process`, {
        params: removeEmpty(params),
      });
    if (response.ok) {
      return {
        dataSource: response.data.data || [],
        total: response.data.total_records,
      };
    }
    return { dataSource: [], total: 0 };
  },
};

export default ProductReviewApi;
