import { RouteRecordRaw } from "vue-router";

const productRoutes: Array<RouteRecordRaw> = [
  {
    path: "/policy",
    name: "Policy",
    component: () =>
      import(
        /* webpackChunkName: "product" */ "../views/PolicyView/PolicyView.vue"
      ),
  },
];

export default productRoutes;
