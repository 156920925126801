import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_avatar = _resolveComponent("a-avatar")!
  const _component_notification_content = _resolveComponent("notification-content")!
  const _component_a_list_item_meta = _resolveComponent("a-list-item-meta")!
  const _component_a_list_item = _resolveComponent("a-list-item")!
  const _component_a_list = _resolveComponent("a-list")!
  const _component_notification_outlined = _resolveComponent("notification-outlined")!
  const _component_a_badge = _resolveComponent("a-badge")!
  const _component_a_popover = _resolveComponent("a-popover")!

  return (_openBlock(), _createBlock(_component_a_popover, {
    trigger: "click",
    placement: "bottomRight"
  }, {
    content: _withCtx(() => [
      _createVNode(_component_a_list, {
        "item-layout": "horizontal",
        "data-source": _ctx.notificationList,
        style: {"width":"460px","max-height":"600px","overflow":"auto"}
      }, {
        renderItem: _withCtx(({ item }) => [
          _createVNode(_component_a_list_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_list_item_meta, null, {
                title: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, _toDisplayString(item.title), 1)
                ]),
                avatar: _withCtx(() => [
                  _createVNode(_component_a_avatar, { src: "https://cdn.autonomous.ai/static/upload/images/common/upload/20210604/autonomousc434b21fb6.png" })
                ]),
                description: _withCtx(() => [
                  _createVNode(_component_notification_content, {
                    content: item.content,
                    "created-date": item.date_created
                  }, null, 8, ["content", "created-date"])
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1024)
        ]),
        _: 1
      }, 8, ["data-source"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_a_badge, {
        dot: !_ctx.seen,
        class: "mr-15 cursor-pointer",
        onClick: _ctx.onViewNotification
      }, {
        default: _withCtx(() => [
          _createVNode(_component_notification_outlined, { style: {"font-size":"16px"} })
        ]),
        _: 1
      }, 8, ["dot", "onClick"])
    ]),
    _: 1
  }))
}