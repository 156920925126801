import { IVendor, IVendorNotification } from "@/@types";
import { removeEmpty } from "@/utils";
import ApiService, { ApiResponse } from "../ApiService";
import { IVendorNotificationListResponse } from "./VendorApi.types";
import { store } from "@/store";

const BASE_URL = "/vendor";

const VendorApi = {
  async getNotificationList(params?: {
    limit?: number;
    page?: number;
    keyword?: string;
  }): Promise<IVendorNotification[]> {
    const response: ApiResponse<IVendorNotificationListResponse> =
      await ApiService.get(`${BASE_URL}/notification-updates`, {
        params: removeEmpty(params),
      });
    if (response.ok) {
      return response.data.data || [];
    }
    return [];
  },
  async getDetail(): Promise<IVendor | undefined> {
    const profile = store.getters["auth/profile"];
    const vendorId = profile?.vendor_id;

    if (!vendorId) {
      return undefined;
    }
    const response: ApiResponse<IVendor> = await ApiService.get(
      `${BASE_URL}/${vendorId}`
    );
    if (response.ok) {
      return response.data;
    }
    return undefined;
  },
  async update(vendor: Partial<IVendor>): Promise<boolean> {
    const profile = store.getters["auth/profile"];
    const vendorId = profile?.vendor_id;

    if (!vendorId) {
      return true;
    }
    const response: ApiResponse<IVendor> = await ApiService.put(
      `${BASE_URL}/${vendorId}`,
      vendor
    );
    return !!response.ok;
  },
};

export default VendorApi;
