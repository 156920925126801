import { RouteRecordRaw } from "vue-router";

const productRoutes: Array<RouteRecordRaw> = [
  {
    path: "/product",
    redirect: "/product/list",
    name: "Product",
  },
  {
    path: "/product/list",
    name: "Product List",
    component: () =>
      import(/* webpackChunkName: "product" */ "../views/ProductListView"),
  },
  {
    path: "/product/create",
    name: "Create Product",
    component: () =>
      import(/* webpackChunkName: "product" */ "../views/ProductDetailView"),
  },
  {
    path: "/product/update/:productId",
    name: "Update Product",
    component: () =>
      import(/* webpackChunkName: "product" */ "../views/ProductDetailView"),
  },
];

export default productRoutes;
