import {
  CommitOptions,
  DispatchOptions,
  Module,
  Store as VuexStore,
} from "vuex";
import { RootState } from "@/store";
import { authState, AuthState } from "./state";
import { authGetters, AuthGetters } from "./getters";
import { AuthMutations, authMutations } from "./mutations";
import { authActions, AuthActions } from "./actions";

export { AuthState };

export type AuthStore<S = AuthState> = Omit<
  VuexStore<S>,
  "getters" | "commit" | "dispatch"
> & {
  commit<
    K extends keyof AuthMutations,
    P extends Parameters<AuthMutations[K]>[1]
  >(
    key: K,
    payload?: P,
    options?: CommitOptions
  ): ReturnType<AuthMutations[K]>;
} & {
  // TODO: How to configure a module without any actions?
  dispatch<K extends keyof AuthActions>(
    key: `auth/${K}`,
    payload?: Parameters<AuthActions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<AuthActions[K]>;
} & {
  getters: {
    [K in keyof AuthGetters as `auth/${K}`]: ReturnType<AuthGetters[K]>;
  };
};

const auth: Module<AuthState, RootState> = {
  state: authState,
  getters: authGetters,
  mutations: authMutations,
  actions: authActions,
  namespaced: true,
};

export * from "./action-types";

export default auth;
