import { MutationTree } from "vuex";

import { AuthState } from "./state";
import { AuthMutationTypes } from "./mutation-types";
import { IProfile } from "@/@types";

export type AuthMutations<S = AuthState> = {
  [AuthMutationTypes.SET_TOKEN](
    state: S,
    payload?: { accessToken: string; refreshToken: string }
  ): void;
  [AuthMutationTypes.SET_PROFILE](state: S, payload?: IProfile): void;
};

export const authMutations: MutationTree<AuthState> & AuthMutations = {
  [AuthMutationTypes.SET_TOKEN](state, payload) {
    state.accessToken = payload?.accessToken;
    state.refreshToken = payload?.refreshToken;
  },
  [AuthMutationTypes.SET_PROFILE](state, profile?: IProfile) {
    state.profile = profile;
  },
};
