import { IOrderDetail } from "@/@types";
import { downloadFile, removeEmpty } from "@/utils";
import ApiService, { ApiResponse, ListResponse } from "../ApiService";
import { IOrderListResponse, IOrderStatusResponse } from "./OrderApi.types";

const BASE_URL = "/order-detail";

const OrderApi = {
  async getList(params: {
    limit?: number;
    last_id?: string;
    date_from?: string;
    date_to?: string;
    statuses?: number | number[];
  }): Promise<ListResponse<IOrderDetail>> {
    const response: ApiResponse<IOrderListResponse> = await ApiService.get(
      `${BASE_URL}`,
      {
        params: removeEmpty(params),
      }
    );
    if (response.ok) {
      return {
        dataSource: response.data.order_details,
        total: response.data.total_records,
      };
    }
    return { dataSource: [], total: 0 };
  },
  async getFilterStatuses(): Promise<IOrderStatusResponse> {
    const response: ApiResponse<IOrderStatusResponse> = await ApiService.get(
      `${BASE_URL}/filter-statuses`
    );
    if (response.ok) {
      return response.data;
    }
    return [];
  },
  async getDetail(orderId: string): Promise<IOrderDetail | null> {
    if (!orderId) {
      return null;
    }
    const res: ApiResponse<IOrderDetail> = await ApiService.get(
      `${BASE_URL}/${orderId}`
    );
    if (res.ok) {
      return res.data;
    }
    return null;
  },
  async export(params?: {
    limit?: number;
    last_id?: string;
    date_from?: string;
    date_to?: string;
    statuses?: number | number[];
    sorted_field?: string;
    sort_order?: number;
  }) {
    const res: ApiResponse<{ fileName: string; file: Blob }> =
      await ApiService.get(`${BASE_URL}/export`, {
        params,
        responseType: "blob",
      });

    if (res.ok) {
      downloadFile(res.data.file, res.data.fileName);
    }
  },
};

export default OrderApi;
