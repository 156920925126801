import slugifyLib from "slugify";
import { UploadFile, UploadProps } from "ant-design-vue";

export const getBase64 = (blob: Blob) => {
  const reader = new FileReader();
  return new Promise((resolve) => {
    reader.addEventListener("load", () => resolve(reader.result));
    reader.readAsDataURL(blob);
  });
};

export const slugifyURL = (text = "", options = {}) => {
  const defaultOptions = {
    lower: true,
    replacement: "-",
    strict: true,
    // eslint-disable-next-line no-useless-escape
    remove: /[\^/!@&#,+()$~%.*'":?<>{}_-]/g,
  };
  return slugifyLib(text, { ...defaultOptions, ...options });
};

export const slugifyCode = (text = "", options = {}) => {
  const defaultOptions = {
    // lower: true,
    replacement: "",
    strict: true,
    remove: /[&#,+()$~%.*'":*?<>{}_-]/g,
  };
  return slugifyLib(text, { ...defaultOptions, ...options });
};

export const isValidYoutubeUrl = (url: string | undefined): boolean => {
  const p =
    /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  return !!url?.match(p);
};

export const getVideoIdFromYoutubeUrl = (youtubeUrl: string): string => {
  const VIDEO_ID_REGEXP =
    /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  let videoId =
    typeof youtubeUrl === "string" ? youtubeUrl.match(VIDEO_ID_REGEXP) : "";
  if (videoId) {
    [, , videoId] = videoId;
  }
  return videoId || "";
};

export const removeEmpty = (
  rawObj?: Record<
    string,
    string | number | undefined | null | Array<string | number>
  >
) => {
  if (!rawObj) {
    return {};
  }

  const obj = { ...rawObj };

  Object.entries(obj).forEach(([key, val]) => {
    if (
      val === undefined ||
      val === null ||
      val === "" ||
      (Array.isArray(val) && !val.length)
    ) {
      delete obj[key];
    }
  });

  return obj;
};

export const downloadCsv = (content: string, filename: string) => {
  // Create a blob
  const blob = new Blob([content], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);

  // Create a link to download it
  const pom = document.createElement("a");
  pom.href = url;
  pom.setAttribute("download", filename);
  pom.click();
};

export const genDefaultUploadFiles = (
  rawFileUrls?: string | UploadFile | Array<string | UploadFile>
): UploadProps["fileList"] => {
  if (!rawFileUrls) {
    return [];
  }

  const fileUrls = Array.isArray(rawFileUrls) ? rawFileUrls : [rawFileUrls];

  return fileUrls.map((fileUrl) => {
    if (typeof fileUrl === "string") {
      return {
        uid: fileUrl,
        url: fileUrl,
        name: "asdasdasdas.jpeg",
        status: "done",
      };
    }
    return fileUrl;
  });
};

export const getContainerScroll = () => document.getElementById("main");

export const extractFileNameFromContentDispositionHeader = (value: string) => {
  const patterns = [
    /filename\*=[^']+'\w*'"([^"]+)";?/i,
    /filename\*=[^']+'\w*'([^;]+);?/i,
    /filename="([^;]*);?"/i,
    /filename=([^;]*);?/i,
  ];

  let responseFilename: any;
  patterns.some((regex) => {
    responseFilename = regex.exec(value);
    return responseFilename !== null;
  });

  if (responseFilename !== null && responseFilename.length > 1) {
    try {
      return decodeURIComponent(responseFilename[1]);
    } catch (e) {
      console.error(e);
    }
  }

  return null;
};

export const downloadFile = (blob: Blob, filename = "data.csv") => {
  const downloadUrl = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = downloadUrl;
  a.download = filename || "data.csv";
  document.body.appendChild(a);
  a.click();
  URL.revokeObjectURL(downloadUrl);
  document.body.removeChild(a);
};
