import { removeEmpty } from "@/utils";
import ApiService, { ApiResponse } from "../ApiService";
import { IInventoryListResponse } from "./InventoryApi.types";
import { IInventorySummary } from "@/@types";

const BASE_URL = "/inventory";

const InventoryApi = {
  async getList(params?: {
    limit?: number;
    page?: number;
    sku?: string;
  }): Promise<{ dataSource: IInventorySummary[]; total: number }> {
    const response: ApiResponse<IInventoryListResponse> = await ApiService.get(
      `${BASE_URL}/sku`,
      { params: removeEmpty(params) }
    );
    if (response.ok) {
      const dataSource: IInventorySummary[] = (response.data.data || []).map(
        (item) => {
          const result: IInventorySummary = {
            alias: item.alias,
            sku: item.sku,
            sku_name: item.sku_name,
            VENDOR: 0,
            VENDOR_CAD: 0,
            VENDOR_EUR: 0,
            VENDOR_GBP: 0,
          };

          item.inventories.forEach((inventory) => {
            if (inventory.warehouse === "VENDOR") {
              result.VENDOR += inventory.quantity || 0;
            } else if (inventory.warehouse === "CAD-VENDOR") {
              result.VENDOR_CAD += inventory.quantity || 0;
            } else if (inventory.warehouse === "GB-VENDOR") {
              result.VENDOR_GBP += inventory.quantity || 0;
            } else {
              result.VENDOR_EUR += inventory.quantity || 0;
            }
          });
          return result;
        }
      );

      return { dataSource, total: response.data.total_records };
    }
    return { dataSource: [], total: 0 };
  },
};

export default InventoryApi;
