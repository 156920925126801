import { API_URL } from "@/config";
import { store } from "@/store";
import { message } from "ant-design-vue";
import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { getGeneralApiProblem, in200s } from "./ApiProblem";
import { ApiResponse, GeneralApiProblem } from "./ApiService.types";
import { extractFileNameFromContentDispositionHeader } from "@/utils";

const apiService = axios.create({
  baseURL: `${API_URL}/api/v1`,
  headers: {
    "Content-Type": "application/json",
  },
  responseType: "json",
  timeout: 60 * 1000, // 10s
});

// function resolveResponse(res) {
//   const { status, data } = res.data;
//   if (status > 0) {
//     return { kind: "ok", data, ok: true };
//   }
//   return { kind: "bad-data", data: res.data, ok: false };
// }
function resolveResponse(res: AxiosResponse) {
  if (!res.data) {
    return { kind: "ok", ok: true, data: true };
  }
  const { status, data } = res.data;
  if (res.data instanceof Blob) {
    const disposition =
      res.headers["content-disposition"] || res.headers["Content-Disposition"];

    return {
      kind: "ok",
      ok: true,
      data: {
        fileName: extractFileNameFromContentDispositionHeader(disposition),
        file: res.data,
      },
    };
  }

  if (in200s(res.status) && !res.data) {
    return { kind: "ok", ok: true, data: true };
  }
  if (status > 0) {
    return { kind: "ok", ok: true, data };
  } else {
    return res.data;
  }
}

async function resolveResponseError(
  error: AxiosError<{ error_message: string; error_code: number }>
): Promise<GeneralApiProblem> {
  if (error.response?.data.error_code) {
    message.error(error.response.data.error_message);
  }

  const res = getGeneralApiProblem(error);
  if (res.kind === "unauthorized") {
    console.log("heheheheheh");
    const isSuccess = await store.dispatch("auth/REFRESH_TOKEN");
    if (isSuccess) {
      return apiService(error.config as AxiosRequestConfig);
    }
  }
  return res;
}

function resolveRequest(config: InternalAxiosRequestConfig) {
  if (config.url === "/auth/sign-in") {
    return config;
  }
  // TODO: get accessToken
  const accessToken = store.state.auth?.accessToken;

  if (accessToken) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
}

apiService.interceptors.request.use(resolveRequest);
apiService.interceptors.response.use(resolveResponse, resolveResponseError);

export const swrFetcher = async (url: string): Promise<unknown> => {
  const response: ApiResponse<unknown> = await apiService.get(url);
  if (response?.kind === "ok") {
    return response.data;
  } else if (response?.kind === "bad-data") {
    throw response.data;
  } else {
    throw new Error(response?.kind);
  }
};

export default apiService;
