import { IProductFaq } from "@/@types";
import { removeEmpty } from "@/utils";
import ApiService, { ApiResponse } from "../ApiService";
import {
  IProductFaqListResponse,
  IProductFaqProcessListResponse,
} from "./ProductFaqApi.types";
import { CONTENT_TYPE } from "@/predefined/contants";

const BASE_URL = "/product-faq";

const ProductFaqApi = {
  async getList(params?: {
    limit?: number;
    page?: number;
    keyword?: string;
    product_code?: string;
    status?: number;
  }): Promise<{ dataSource: IProductFaq[]; total: number }> {
    const response: ApiResponse<IProductFaqListResponse> = await ApiService.get(
      `${BASE_URL}`,
      { params: removeEmpty(params) }
    );

    if (response.ok) {
      return { dataSource: response.data.data, total: response.data.total };
    }
    return { dataSource: [], total: 0 };
  },
  async createOrUpdate(payload: Partial<IProductFaq>) {
    const response: ApiResponse<{ id: string }> = payload.id
      ? await ApiService.put(`${BASE_URL}/${payload.id}`, payload)
      : await ApiService.post(`${BASE_URL}`, payload);
    return response;
  },
  async getDetail(productFaqId?: string): Promise<IProductFaq | null> {
    if (!productFaqId) {
      return null;
    }
    const res: ApiResponse<IProductFaq> = await ApiService.get(
      `${BASE_URL}/${productFaqId}`
    );
    if (res.ok) {
      return res.data;
    }
    return null;
  },
  async import(file: Blob): Promise<boolean> {
    const formData = new FormData();

    formData.append("file", file);
    const response: string = await ApiService.post(
      `${BASE_URL}/import`,
      formData,
      {
        headers: { "Content-Type": CONTENT_TYPE.MULTIPART },
        responseType: "text",
        params: {
          public: true,
        },
      }
    );
    return !!response;
  },
  async getProcessList(params?: {
    limit?: number;
    page?: number;
    keyword?: string;
    product_code?: string;
    status?: number;
  }) {
    const response: ApiResponse<IProductFaqProcessListResponse> =
      await ApiService.get(`${BASE_URL}/import-process`, {
        params: removeEmpty(params),
      });
    if (response.ok) {
      return {
        dataSource: response.data.data || [],
        total: response.data.total_records,
      };
    }
    return { dataSource: [], total: 0 };
  },
};

export default ProductFaqApi;
