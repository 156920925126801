import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-524f23e6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "time" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass({
        content: true,
        collapsible: _ctx.isLongText,
        collapsed: !_ctx.isShowAll,
      })
    }, _toDisplayString(_ctx.content), 3),
    (_ctx.isLongText)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: "action",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)))
        }, _toDisplayString(_ctx.isShowAll ? "Show less" : "Show more"), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.formattedCreatedDate), 1)
  ]))
}