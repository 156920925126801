
import { defineComponent } from "vue";
import PreviewImageModal from "./components/PreviewImageModal.vue";
import CropImageModal from "./components/CropImageModal.vue";

export default defineComponent({
  components: { PreviewImageModal, CropImageModal },
  setup() {
    return {};
  },
});
