import {
  CommitOptions,
  DispatchOptions,
  Module,
  Store as VuexStore,
} from "vuex";
import { RootState } from "@/store";
import { modalState, ModalState } from "./state";
import { modalGetters, ModalGetters } from "./getters";
import { ModalMutations, modalMutations } from "./mutations";
import { modalActions, ModalActions } from "./actions";

export { ModalState };

export type ModalStore<S = ModalState> = Omit<
  VuexStore<S>,
  "getters" | "commit" | "dispatch"
> & {
  commit<
    K extends keyof ModalMutations,
    P extends Parameters<ModalMutations[K]>[1]
  >(
    key: K,
    payload?: P,
    options?: CommitOptions
  ): ReturnType<ModalMutations[K]>;
} & {
  // TODO: How to configure a module without any actions?
  dispatch<K extends keyof ModalActions>(
    key: `modal/${K}`,
    payload?: Parameters<ModalActions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<ModalActions[K]>;
} & {
  getters: {
    [K in keyof ModalGetters as `modal/${K}`]: ReturnType<ModalGetters[K]>;
  };
};

const modal: Module<ModalState, RootState> = {
  state: modalState,
  getters: modalGetters,
  mutations: modalMutations,
  actions: modalActions,
  namespaced: true,
};

export * from "./action-types";

export default modal;
