export const ENUM_MIME_TYPE = {
  IMAGE: {
    id: "image/*",
    typeRe: /image\/.*$/,
  },
  AUDIO: {
    id: "audio/*",
    typeRe: /audio\/.$/,
  },
  VIDEO: {
    id: "video/*",
    typeRe: /video\/.$/,
  },
  PDF: {
    id: "application/pdf",
    typeRe: /application\/pdf/,
  },
  CSV: {
    id: ".csv",
    nameRe: /.csv$/,
  },
  EXCEL: {
    id: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
    typeRe:
      /(application\/vnd.openxmlformats-officedocument.spreadsheetml.sheet|application\/vnd.openxmlformats-officedocument.spreadsheetml.sheet)/,
  },
  CSV_EXCEL: {
    id: ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
    typeRe:
      /(.csv$|application\/vnd.openxmlformats-officedocument.spreadsheetml.sheet|application\/vnd.openxmlformats-officedocument.spreadsheetml.sheet)/,
  },
};
