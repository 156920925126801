import { GetterTree } from "vuex";
import { RootState } from "@/store";

import { ModalState } from "./state";

export type ModalGetters = {
  previewImage(state: ModalState): ModalState["previewImage"];
  cropImage(state: ModalState): ModalState["cropImage"];
};

export const modalGetters: GetterTree<ModalState, RootState> & ModalGetters = {
  previewImage: (state) => state.previewImage,
  cropImage: (state) => state.cropImage,
};
