import { GetterTree } from "vuex";
import { RootState } from "@/store";

import { AuthState } from "./state";
import { IProfile } from "@/@types";

export type AuthGetters = {
  profile(state: AuthState): IProfile | undefined;
};

export const authGetters: GetterTree<AuthState, RootState> & AuthGetters = {
  profile: (state) => state.profile,
};
