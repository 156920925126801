import { RouteRecordRaw } from "vue-router";

const vendorRoutes: Array<RouteRecordRaw> = [
  {
    path: "/vendor",
    name: "Vendor",
    redirect: "/vendor-detail",
  },
  {
    path: "/vendor-slug",
    name: "Vendor Url",
    component: () =>
      import(/* webpackChunkName: "vendor" */ "../views/VendorUrlView"),
  },
  {
    path: "/vendor-detail",
    name: "Vendor Detail",
    component: () =>
      import(/* webpackChunkName: "vendor" */ "../views/VendorDetailView"),
  },
];

export default vendorRoutes;
