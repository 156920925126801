import { createApp } from "vue";
import Antd from "ant-design-vue";
import vue3GoogleLogin from "vue3-google-login";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import { GOOGLE_CLIENT_ID } from "./config";

import "./ant-design-vue.less";
import "./styles/index.less";

const app = createApp(App);

app.use(store).use(router);
app.use(Antd);
app.use(vue3GoogleLogin, {
  clientId: GOOGLE_CLIENT_ID,
});

app.mount("#app");
