import axios, { AxiosError } from "axios";

import { GeneralApiProblem } from "./ApiService.types";

export const NONE = null;
export const CLIENT_ERROR = "CLIENT_ERROR";
export const SERVER_ERROR = "SERVER_ERROR";
export const TIMEOUT_ERROR = "TIMEOUT_ERROR";
export const CONNECTION_ERROR = "CONNECTION_ERROR";
export const NETWORK_ERROR = "NETWORK_ERROR";
export const UNKNOWN_ERROR = "UNKNOWN_ERROR";
export const CANCEL_ERROR = "CANCEL_ERROR";

type StatusCodes = undefined | number;
const TIMEOUT_ERROR_CODES = ["ECONNABORTED"];
const NODEJS_CONNECTION_ERROR_CODES = [
  "ENOTFOUND",
  "ECONNREFUSED",
  "ECONNRESET",
];
const isWithin = (min: number, max: number, value: number): boolean =>
  value >= min && value <= max;
export const in200s = (n: number): boolean => isWithin(200, 299, n);
const in400s = (n: number): boolean => isWithin(400, 499, n);
const in500s = (n: number): boolean => isWithin(500, 599, n);

export const getProblemFromStatus = (status: StatusCodes): string | null => {
  if (!status) return UNKNOWN_ERROR;
  if (in200s(status)) return NONE;
  if (in400s(status)) return CLIENT_ERROR;
  if (in500s(status)) return SERVER_ERROR;
  return UNKNOWN_ERROR;
};

export const getProblemFromError = (error: AxiosError) => {
  if (error.message === "Network Error") {
    return NETWORK_ERROR;
  }

  // if (axios.isCancel(error)) {
  //   return CANCEL_ERROR;
  // }

  // then check the specific error code
  // if (!error.code) {
  return getProblemFromStatus(error.response?.status);
  // }

  // if (TIMEOUT_ERROR_CODES.includes(error.code)) {
  //   return TIMEOUT_ERROR;
  // }

  // if (NODEJS_CONNECTION_ERROR_CODES.includes(error.code)) {
  //   return CONNECTION_ERROR;
  // }

  // return UNKNOWN_ERROR;
};

/**
 * Attempts to get a common cause of problems from an api response.
 *
 * @param response The api response.
 */
export function getGeneralApiProblem(error: AxiosError): GeneralApiProblem {
  const problem = getProblemFromError(error);

  switch (problem) {
    case "CONNECTION_ERROR":
      return { kind: "cannot-connect", ok: false };
    case "NETWORK_ERROR":
      return { kind: "cannot-connect", ok: false };
    case "TIMEOUT_ERROR":
      return { kind: "timeout", ok: false };
    case "SERVER_ERROR":
      return {
        kind: "server",
        data: { message: "Server Error", status: -1, data: null },
        ok: false,
      };
    case "UNKNOWN_ERROR":
      return { kind: "unknown", ok: false };
    case "CLIENT_ERROR":
      switch (error.response?.status) {
        case 401:
          return { kind: "unauthorized", ok: false };
        case 403:
          return { kind: "forbidden", ok: false };
        case 404:
          return { kind: "not-found", ok: false };
        default:
          return {
            kind: "rejected",
            data: error.response?.data,
            ok: false,
          };
      }
    default:
      return { kind: "unknown", ok: false };
  }
}
