import { CONTENT_TYPE } from "@/predefined/contants";
import ApiService, { ApiResponse, GeneralApiProblem } from "../ApiService";
import { message } from "ant-design-vue";
import { IProcessListResponse } from "./CommonApi.types";
import { removeEmpty } from "@/utils";

const BASE_URL = "/common-api";

const CommonApi = {
  async uploadFile(file: Blob): Promise<string> {
    if (!file) {
      return "";
    }
    const formData = new FormData();

    formData.append("file", file);
    const response: string | GeneralApiProblem = await ApiService.post(
      `${BASE_URL}/file`,
      formData,
      {
        headers: { "Content-Type": CONTENT_TYPE.MULTIPART },
        responseType: "text",
        params: {
          public: true,
        },
      }
    );

    if (typeof response === "string") {
      return response;
    }

    if (response.ok) {
      return response.data;
    }

    try {
      const data = JSON.parse(response.data);
      message.error(data.error_message || "Upload failed");
    } catch {
      message.error("Upload failed!");
    }
    return "";
  },
  async getProcessList(params?: {
    limit?: number;
    page?: number;
    keyword?: string;
    product_code?: string;
    status?: number;
    type?: number;
  }) {
    const response: ApiResponse<IProcessListResponse> = await ApiService.get(
      `${BASE_URL}/import/process`,
      {
        params: removeEmpty(params),
      }
    );
    if (response.ok) {
      return {
        dataSource: response.data.data || [],
        total: response.data.total_records,
      };
    }
    return { dataSource: [], total: 0 };
  },
};

export default CommonApi;
