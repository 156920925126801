import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-723e58f4"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "data-src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    visible: _ctx.previewImageState.visible,
    footer: null,
    onCancel: _ctx.cancel,
    width: "auto",
    class: "image-preview-dialog",
    centered: "",
    destroyOnClose: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("img", {
        src: _ctx.previewImageState.thumbUrl,
        "data-src": _ctx.previewImageState.src,
        alt: "Loading",
        class: "lazyload",
        style: {"min-height":"100px"}
      }, null, 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["visible", "onCancel"]))
}