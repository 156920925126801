import { LayoutDefault } from "@/layout";
import LoginView from "@/views/LoginView";
import ForgotPasswordView from "@/views/ForgotPasswordView";
import ChangePasswordView from "@/views/ChangePasswordView";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import orderRoutes from "./orderRoutes";
import productRoutes from "./productRoutes";
import policyRoutes from "./policyRoutes";
import importRoutes from "./importRoutes";
import productReviewRoutes from "./productReviewRoutes";
import vendorRoutes from "./vendorRoutes";
import inventoryRoutes from "./inventoryRoutes";
import productFaqRoutes from "./productFaqRoutes";

export const adminRoutes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: LayoutDefault,
    redirect: "/product",
    children: [
      ...orderRoutes,
      ...productRoutes,
      ...importRoutes,
      ...policyRoutes,
      ...productReviewRoutes,
      ...vendorRoutes,
      ...inventoryRoutes,
      ...productFaqRoutes,
    ],
  },
];
export const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "Login",
    component: LoginView,
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    component: ForgotPasswordView,
  },
  {
    path: "/change-password",
    name: "Change Password",
    component: ChangePasswordView,
  },
  ...adminRoutes,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
