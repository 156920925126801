import _pick from "lodash/pick";
import _isEqual from "lodash/isEqual";

export function getUpdatedFields<T extends Record<string, any>>(
  data?: T,
  defaultData?: T,
  ignoreFields?: string[]
) {
  if (!data || !defaultData) {
    return [];
  }
  const updatedKeys = Object.keys(data).filter((key) => {
    if (key.includes("clientOnly") || ignoreFields?.includes(key)) {
      return false;
    }
    return !_isEqual(data[key], defaultData[key]);
  });
  return updatedKeys;
}

export const hasChangedData = (
  data?: Record<string, any>,
  defaultData?: Record<string, any>,
  ignoreFields?: string[]
) => {
  if (!data || !defaultData) {
    return false;
  }
  const updatedKeys = getUpdatedFields(data, defaultData, ignoreFields);
  return !!updatedKeys.length;
};

export function getUpdatedData<T extends Record<string, any>>(
  data: T,
  defaultData: T,
  ignoreFields?: string[]
) {
  const updatedKeys = getUpdatedFields(data, defaultData, ignoreFields);

  return _pick(data, updatedKeys.concat("id", "_id")) as Partial<T>;
}
