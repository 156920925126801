import { ActionContext, ActionTree } from "vuex";
import { RootState } from "@/store";

import { AuthState } from "./state";
import { AuthActionTypes } from "./action-types";
import { AuthMutationTypes } from "./mutation-types";
import { AuthApi } from "@/apis";
import router from "@/router";
import { AuthMutations } from "./mutations";

type AugmentedActionContext = {
  commit<K extends keyof AuthMutations>(
    key: K,
    payload: Parameters<AuthMutations[K]>[1]
  ): ReturnType<AuthMutations[K]>;
} & Omit<ActionContext<AuthState, RootState>, "commit">;

export interface AuthActions {
  [AuthActionTypes.LOGIN](
    { commit, state }: AugmentedActionContext,
    payload: { email: string; password: string }
  ): Promise<boolean>;
  [AuthActionTypes.SWITCH_VENDOR](
    { commit, state }: AugmentedActionContext,
    vendorCode: string
  ): Promise<boolean>;
  [AuthActionTypes.LOGIN_GOOGLE](
    { commit }: AugmentedActionContext,
    token: string
  ): Promise<boolean>;
  [AuthActionTypes.LOGOUT]({ commit }: AugmentedActionContext): void;
  [AuthActionTypes.GET_PROFILE]({ commit }: AugmentedActionContext): void;
  [AuthActionTypes.REFRESH_TOKEN]({
    commit,
  }: AugmentedActionContext): Promise<boolean>;
}

export const authActions: ActionTree<AuthState, RootState> & AuthActions = {
  async [AuthActionTypes.LOGIN]({ commit }, payload) {
    const res = await AuthApi.login(payload);

    if (res?.ok) {
      commit(AuthMutationTypes.SET_TOKEN, {
        accessToken: res.data.access_token,
        refreshToken: res.data.refresh_token,
      });
    }

    return res.ok;
  },
  async [AuthActionTypes.SWITCH_VENDOR]({ commit }, payload) {
    const res = await AuthApi.switchVendor(payload);

    if (res?.ok) {
      commit(AuthMutationTypes.SET_TOKEN, {
        accessToken: res.data.access_token,
        refreshToken: res.data.refresh_token,
      });
    }

    return res.ok;
  },
  async [AuthActionTypes.LOGIN_GOOGLE]({ commit }, token) {
    const res = await AuthApi.loginGoogle(token);

    if (res?.ok) {
      commit(AuthMutationTypes.SET_TOKEN, {
        accessToken: res.data.access_token,
        refreshToken: res.data.refresh_token,
      });
    }

    return res.ok;
  },
  async [AuthActionTypes.REFRESH_TOKEN]({ commit, state }) {
    const refreshToken = state.refreshToken;

    if (!refreshToken) {
      router.push("/login");
      return false;
    }

    commit(AuthMutationTypes.SET_TOKEN, undefined);
    const res = await AuthApi.refreshToken(refreshToken);
    if (!res?.ok) {
      router.push("/login");
      return false;
    } else {
      commit(AuthMutationTypes.SET_TOKEN, {
        accessToken: res.data.access_token,
        refreshToken: res.data.refresh_token,
      });
      return true;
    }
  },
  async [AuthActionTypes.LOGOUT]({ commit }) {
    commit(AuthMutationTypes.SET_PROFILE, undefined);
    commit(AuthMutationTypes.SET_TOKEN, {
      accessToken: "",
      refreshToken: "",
    });
    router.push("/login");
  },
  async [AuthActionTypes.GET_PROFILE]({ commit }) {
    const res = await AuthApi.getProfile();

    if (res?.ok) {
      commit(AuthMutationTypes.SET_PROFILE, res.data);
    }

    return res.ok;
  },
};
