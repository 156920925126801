import { ActionContext, ActionTree } from "vuex";
import { RootState } from "@/store";

import { ModalState } from "./state";
import { ModalActionTypes } from "./action-types";
import { ModalMutationTypes } from "./mutation-types";
import router from "@/router";
import { ModalMutations } from "./mutations";

type AugmentedActionContext = {
  commit<K extends keyof ModalMutations>(
    key: K,
    payload: Parameters<ModalMutations[K]>[1]
  ): ReturnType<ModalMutations[K]>;
  state: any;
} & Omit<ActionContext<ModalState, RootState>, "commit">;

export interface ModalActions {
  [ModalActionTypes.SHOW_PREVIEW_IMAGE_MODAL](
    { commit }: AugmentedActionContext,
    data: string | { thumbUrl?: string; src?: string }
  ): void;
  [ModalActionTypes.HIDE_PREVIEW_IMAGE_MODAL]({
    commit,
  }: AugmentedActionContext): void;
  [ModalActionTypes.SHOW_CROP_IMAGE_MODAL](
    { commit }: AugmentedActionContext,
    data?: {
      file?: File;
      width?: number;
      ratio?: number;
      onFinish?: (image?: File) => void;
      onCancel?: () => void;
    }
  ): void;
  [ModalActionTypes.HIDE_CROP_IMAGE_MODAL]({
    commit,
  }: AugmentedActionContext): void;
}

export const modalActions: ActionTree<ModalState, RootState> & ModalActions = {
  async [ModalActionTypes.SHOW_PREVIEW_IMAGE_MODAL]({ commit }, payload) {
    const newData = typeof payload === "string" ? { src: payload } : payload;
    commit(ModalMutationTypes.SET_PREVIEW_IMAGE_MODAL, {
      visible: true,
      ...newData,
    });
  },
  async [ModalActionTypes.HIDE_PREVIEW_IMAGE_MODAL]({ commit }) {
    commit(ModalMutationTypes.SET_PREVIEW_IMAGE_MODAL, { visible: false });
  },
  async [ModalActionTypes.SHOW_CROP_IMAGE_MODAL](
    { commit, rootState },
    payload
  ) {
    commit(ModalMutationTypes.SET_CROP_IMAGE_MODAL, {
      visible: true,
      data: [...(rootState.modal.cropImage?.data || []), payload || {}],
    });
  },
  async [ModalActionTypes.HIDE_CROP_IMAGE_MODAL]({ commit }) {
    commit(ModalMutationTypes.SET_CROP_IMAGE_MODAL, {
      visible: false,
      data: [],
    });
  },
};
