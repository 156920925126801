
import { FormInstance, Rule } from "ant-design-vue/lib/form";
import { defineComponent, reactive, ref } from "vue";
import { AuthApi } from "@/apis";
import { useRoute, useRouter } from "vue-router";
import { Modal } from "ant-design-vue";

interface IFormState {
  new_password: string;
  confirm_new_password: string;
}

export default defineComponent({
  setup() {
    const formState = reactive<IFormState>({
      new_password: "",
      confirm_new_password: "",
    });
    const loading = ref<boolean>(false);
    const visibleForm = ref<boolean>(true);
    const formRef = ref<FormInstance>();
    const router = useRouter();
    const { query } = useRoute();
    const token = query.token as string;
    const email = query.email as string;

    const rules: Partial<Record<keyof IFormState, Rule[]>> = {
      new_password: [
        {
          required: true,
          validator: async (_rule: Rule, value: string) => {
            if (value === "") {
              return Promise.reject("Please input the password");
            } else {
              if (formState.confirm_new_password !== "" && formRef.value) {
                formRef.value.validateFields("checkPass");
              }
              return Promise.resolve();
            }
          },
        },
      ],
      confirm_new_password: [
        {
          validator: async (_rule: Rule, value: string) => {
            if (value === "") {
              return Promise.reject("Please input the password again");
            } else if (value !== formState.new_password) {
              return Promise.reject("Two inputs don't match!");
            } else {
              return Promise.resolve();
            }
          },
        },
      ],
    };

    const onSubmit = async (values: IFormState) => {
      loading.value = true;
      const isSuccess = await AuthApi.resetPassword({
        ...values,
        token,
        email,
      });
      if (isSuccess) {
        Modal.info({
          title:
            "Your password has been reset successfully. Please use the new password to log in to your account.",
          okText: "Continue",
          onOk() {
            router.push("/login");
          },
        });
      }
      loading.value = false;
    };

    return {
      loading,
      formState,
      rules,
      onSubmit,
      visibleForm,
    };
  },
});
