import { removeEmpty } from "@/utils";
import ApiService, { ApiResponse } from "../ApiService";
import { ITrackingProcessListResponse } from "./TrackingApi.types";
import { CONTENT_TYPE } from "@/predefined/contants";

const BASE_URL = "/tracking";

const TrackingApi = {
  async getProcessList(params?: {
    limit?: number;
    page?: number;
    keyword?: string;
    product_code?: string;
    status?: number;
  }) {
    const response: ApiResponse<ITrackingProcessListResponse> =
      await ApiService.get(`${BASE_URL}/import_tracking_process`, {
        params: removeEmpty(params),
      });
    if (response.ok) {
      return {
        dataSource: response.data.import_tracking_processes || [],
        total: response.data.total_records,
      };
    }
    return { dataSource: [], total: 0 };
  },
  async import(file: Blob): Promise<boolean> {
    const formData = new FormData();

    formData.append("file", file);
    const response: string = await ApiService.post(
      `${BASE_URL}/import`,
      formData,
      {
        headers: { "Content-Type": CONTENT_TYPE.MULTIPART },
        responseType: "text",
        params: {
          public: true,
        },
      }
    );
    return !!response;
  },
};

export default TrackingApi;
