import { createStore } from "vuex";
import auth, { AuthState, AuthStore } from "@/store/modules/auth";
import VuexPersistence from "vuex-persist";
import modal, { ModalState, ModalStore } from "./modules/modal";

export type RootState = {
  auth: AuthState;
  modal: ModalState;
};

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

export type Store = AuthStore<Pick<RootState, "auth">> &
  ModalStore<Pick<RootState, "modal">>;
export const store = createStore<RootState>({
  plugins: [vuexLocal.plugin],
  modules: {
    auth,
    modal,
  },
}) as Store;

export function useStore(): Store {
  return store as Store;
}
