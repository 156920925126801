
import { Rule } from "ant-design-vue/lib/form";
import { defineComponent, onMounted, reactive, ref } from "vue";
import { useStore } from "@/store";
import { useRouter } from "vue-router";
import { CallbackTypes } from "vue3-google-login";

interface IFormState {
  email: string;
  password: string;
}

export default defineComponent({
  setup() {
    const store = useStore();
    const router = useRouter();
    const formState = reactive<IFormState>({
      email: "",
      password: "",
    });
    const loading = ref<boolean>(false);

    const rules: Partial<Record<keyof IFormState, Rule[]>> = {
      email: [
        { type: "email", message: "Please input valid email!" },
        { required: true, message: "Please input your email!" },
      ],
      password: [{ required: true, message: "Please input your password!" }],
    };

    const onSubmit = async (values: IFormState) => {
      loading.value = true;
      const isSuccess = await store.dispatch("auth/LOGIN", values);
      if (isSuccess) {
        router.push("/");
      }
      loading.value = false;
    };

    const googleCallback: CallbackTypes.CredentialCallback = async (res) => {
      loading.value = true;
      const isSuccess = await store.dispatch(
        "auth/LOGIN_GOOGLE",
        res.credential
      );
      if (isSuccess) {
        router.push("/");
      }
      loading.value = false;
    };

    onMounted(() => {
      if (store.state.auth.accessToken) {
        router.push("/");
      }
    });

    return {
      loading,
      formState,
      rules,
      onSubmit,
      googleCallback,
    };
  },
});
