import { store } from "@/store";
import ApiService, { ApiResponse } from "../ApiService";
import { ILoginResponse } from "./AuthApi.types";
import { IProfile } from "@/@types";

const BASE_URL = "/auth";

const AuthApi = {
  async login(params: { password: string; email: string }) {
    const payload = {
      ...params,
      grant_type: "password",
    };
    const response: ApiResponse<ILoginResponse> = await ApiService.post(
      `${BASE_URL}/sign-in`,
      payload
    );
    return response;
  },
  async loginGoogle(token: string) {
    const payload = {
      grant_type: "social_token",
      provider: "google",
      token,
    };
    const response: ApiResponse<ILoginResponse> = await ApiService.post(
      `${BASE_URL}/sign-in`,
      payload
    );
    return response;
  },
  async getProfile() {
    const response: ApiResponse<IProfile> = await ApiService.get(`/me/profile`);
    return response;
  },
  async switchVendor(vendorCode: string) {
    const response: ApiResponse<ILoginResponse> = await ApiService.post(
      `/me/switch-vendor`,
      { vendor_code: vendorCode }
    );
    return response;
  },
  async refreshToken(
    refreshToken?: string
  ): Promise<ApiResponse<ILoginResponse> | null> {
    if (!refreshToken) {
      return null;
    } else {
      const payload = {
        grant_type: "refresh_token",
        refresh_token: refreshToken,
      };
      const response: ApiResponse<ILoginResponse> = await ApiService.post(
        `${BASE_URL}/sign-in`,
        payload
      );
      return response;
    }
  },
  async forgotPassword(email: string): Promise<boolean> {
    const response: ApiResponse<unknown> = await ApiService.post(
      `${BASE_URL}/forgot-password`,
      { email }
    );
    return !!response.ok;
  },
  async resetPassword(params: {
    confirm_new_password: string;
    email: string;
    new_password: string;
    token: string;
  }): Promise<boolean> {
    const response: ApiResponse<unknown> = await ApiService.post(
      `${BASE_URL}/reset-password`,
      params
    );
    return !!response.ok;
  },
};

export default AuthApi;
